import { useState } from "react";
import { Amplify, API } from "aws-amplify";

import { awsConfig } from "../store/awsConfig";
import { error } from "../components/Toast";

type UseApiProps = {
  path: string;
};

type RequestProps = {
  method: "post" | "put" | "get" | "del";
  pathParameters?: any;
  queryStringParameters?: any;
  body?: any;
  skipToast?: boolean;
};

type UseApiReturn = {
  request: ({
    method,
    pathParameters,
    queryStringParameters,
    body,
  }: RequestProps) => Promise<any>;
  processing: boolean;
};

Amplify.configure(awsConfig);
const apiRetinaMonitorator = awsConfig.API.endpoints[0].name;

export function useApi({ path }: UseApiProps): UseApiReturn {
  const [processing, setProcessing] = useState(false);

  const request = async ({
    method,
    pathParameters,
    queryStringParameters,
    body,
    skipToast,
  }: RequestProps) => {
    try {
      setProcessing(true);

      const pathUrl = pathParameters ? `${path}/${pathParameters}` : path;

      const response = await API[method](apiRetinaMonitorator, pathUrl, {
        queryStringParameters,
        body,
      });
      setProcessing(false);
      return response;
    } catch (err: any) {
      const { data }: any = err?.response || {};
      if (!skipToast) {
        error(data?.message || "Failed to request");
      }
      setProcessing(false);
      throw err;
    }
  };

  return { request, processing };
}

export default useApi;
