import styled from "styled-components";

export const ContainerFilter = styled.div<{ show: boolean }>`
  display: ${({ show }) => !show && "none"};
  position: absolute;
  right: 0;

  width: 50vw;

  -webkit-box-shadow: -22px 2px 22px -14px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: -22px 2px 22px -14px rgba(0, 0, 0, 0.3);
  box-shadow: -22px 2px 22px -14px rgba(0, 0, 0, 0.3);

  background-color: ${(props) => props.theme.colors.divBackground};
  border: 1px solid ${(props) => props.theme.colors.cardHighlight};
  border-radius: 6px;
  margin-left: 10px;
  padding: 10px;
  /* height: calc(100vh - 130px); */

  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Title = styled.h2`
  color: ${(props) => props.theme.colors.textPrimary};
  ${(props) => props.theme.fonts.primary.H2Bold};
`;

export const ContainerActions = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: space-evenly;
`;

export const ContainerInputs = styled.form`
  width: 100%;
`;
