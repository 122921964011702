import React from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import useApi from "../../hooks/useApi";
import { Button } from "../Button";
import { ModalConfirm } from "../ModalConfirm";
import queryString from "query-string";
import { ContainerActions, HeaderContainer, PageTitle } from "./styles";

import { PageHeaderProps } from "./types";
import SummaryHistoryHeader from "../../modules/summary/components/SummaryListHeader";
import { ModalExportCSV } from "../ModalExportCSV";

export const PageHeader = ({
  handleClickFilter,
  resource,
  showResourceActions,
  showRecordActions,
  resultCount,
  queryTotal,
  record,
  handleSearch = () => null,
}: PageHeaderProps) => {
  const navigate = useNavigate();

  const { request } = useApi({ path: resource.apiPath });

  const customResourceActions = Object.values(resource.actions).filter(
    (action: any) => action.type === "resource"
  );

  const customRecordActions = Object.values(resource.actions).filter(
    (action: any) => action.type === "record"
  );

  const customDeleteAction = resource.actions?.delete?.handler;

  const primaryKey: any = Object.values(resource.properties).find(
    (property: any) => property.isPrimaryKey
  );

  const fieldId = primaryKey ? primaryKey.id : "id";

  const location = useLocation();

  const [, setQueryString] = useSearchParams();

  if (resource.tabelao && showResourceActions) {
    return (
      <HeaderContainer>
        <SummaryHistoryHeader
          type={resource.tabelao}
          total={queryTotal}
          filtered={resultCount}
          title={resource.title}
          handleSearch={handleSearch}
        />
        <div style={{ display: "flex", gap: "8px" }}>
          {customResourceActions.map((action: any, index: number) => {
            const { label, name, modalComponent, Component } = action;

            if (modalComponent) {
              return modalComponent();
            }

            if (Component) {
              return Component();
            }

            return (
              <Button
                key={index}
                onClick={() => navigate(`${resource.urlPath}/${name}`)}
              >
                {label}
              </Button>
            );
          })}
          {resource?.actions?.csv?.isVisible && (
            <ModalExportCSV resource={resource} />
          )}
          {resource.title === "Gateways" && (
            <Button
              onClick={() => {
                setQueryString({
                  isActivated: true,
                  isConnected: false,
                } as any);
              }}
            >
              Ver Offline
            </Button>
          )}
          {resource.title === "Endpoints" && (
            <Button
              onClick={() => {
                let query = queryString.parse(location.search);
                delete query.page;
                setQueryString({
                  ...query,
                  isActivated: true,
                } as any);
              }}
            >
              Ativados
            </Button>
          )}
          <Button onClick={handleClickFilter} variant="primary">
            Filtro
          </Button>
        </div>
      </HeaderContainer>
    );
  }

  return (
    <HeaderContainer>
      <div>
        <PageTitle>
          {resource.title}
          {!!resultCount && ` (${resultCount})`}
        </PageTitle>
      </div>
      {showResourceActions && (
        <div>
          {resource?.actions?.csv?.isVisible && (
            <ModalExportCSV resource={resource} />
          )}
          {customResourceActions.map((action: any, index: number) => {
            const { label, name, modalComponent, Component } = action;

            if (modalComponent) {
              return modalComponent();
            }

            if (Component) {
              return Component();
            }

            return (
              <Button
                key={index}
                onClick={() => navigate(`${resource.urlPath}/${name}`)}
              >
                {label}
              </Button>
            );
          })}
          {(!resource.actions.new || resource.actions?.new?.isVisible) && (
            <Button onClick={() => navigate(`${resource.urlPath}/new`)}>
              + Novo
            </Button>
          )}
          {(!resource.actions.filter ||
            resource.actions?.filter?.isVisible) && (
            <Button onClick={handleClickFilter} variant="primary">
              Filtro
            </Button>
          )}
        </div>
      )}
      {showRecordActions && (
        <ContainerActions>
          {customRecordActions.map((action: any, index: number) => {
            return (
              <Button
                key={index}
                onClick={() => {
                  if (action.component) {
                    return navigate(
                      `${resource.urlPath}/${action.name}/${record[fieldId]}`
                    );
                  }
                }}
              >
                {action.component ? (
                  action.label
                ) : (
                  <ModalConfirm
                    message={action.guard}
                    handleConfirm={() => {
                      action.handler(record).then(() => {
                        return navigate(`${resource.urlPath}`);
                      });
                    }}
                  >
                    {action.label}
                  </ModalConfirm>
                )}
              </Button>
            );
          })}
          {(!resource.actions.delete ||
            resource.actions?.delete?.isVisible) && (
            <ModalConfirm
              message={`Tem certeza que deseja excluir ${record?.name}?`}
              handleConfirm={() => {
                if (customDeleteAction) {
                  customDeleteAction(record).then(() => {
                    navigate(resource.urlPath);
                  });
                } else {
                  request({
                    method: "del",
                    pathParameters: record[fieldId],
                  }).then(() => {
                    navigate(resource.urlPath);
                  });
                }
              }}
            >
              <Button onClick={() => {}}>Deletar</Button>
            </ModalConfirm>
          )}
          {(!resource.actions.edit || resource.actions?.edit?.isVisible) && (
            <Button
              onClick={() =>
                navigate(`${resource.urlPath}/edit/${record[fieldId]}`)
              }
            >
              Editar
            </Button>
          )}
        </ContainerActions>
      )}
    </HeaderContainer>
  );
};
