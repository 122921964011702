import styled from "styled-components";
import { ContentContainerProps } from "./types";

export const Container = styled.div`
  background-color: ${(props) => props.theme.colors.background};
  /* height: calc(100vh - 130px); */
  display: flex;
  width: 100%;
`;

export const ContentContainer = styled.div<ContentContainerProps>`
  margin-left: 15px;
  width: 100%;
  height: 100%;

  @media (max-width: 767px) {
    width: ${(props) => (props.visible ? "0px" : "100%")};
    opacity: ${(props) => (props.visible ? "0" : "1")};
    transition: width 0.3s linear, opacity 0.3s linear;
  }
`;

export const CardStatusContainer = styled.div`
  display: flex;
  margin-bottom: 10px;

  height: 30%;

  & > div {
    & + div {
      margin-left: 10px;
    }
  }
`;

export const List = styled.ul`
  margin-top: 10px;
  list-style: none;
  padding: 0;
  overflow-y: scroll;
  height: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
  padding-bottom: 10px;
`;

export const ListItem = styled.button`
  background-color: ${(props) => props.theme.colors.cardBackground};
  color: ${(props) => props.theme.colors.iconsSecondary};
  width: 100%;
  border: 1px solid ${(props) => props.theme.colors.cardHighlight};
  border-radius: 6px;
  margin-bottom: 5px;
  cursor: pointer;
  padding: 10px;
  display: flex;
  flex-direction: column;
  text-decoration: none;

  &:hover {
    text-decoration: none;
    color: ${(props) => props.theme.colors.iconsSecondary};
    opacity: 0.8;
  }
`;

export const BoltStatus = styled.span<{ status: string }>`
  color: ${(props) => (props.status === "online" ? "green" : "red")};
`;

export const ListContainer = styled.div`
  display: flex;
  height: 65%;
`;

export const CardList = styled.div`
  position: relative;
  background-color: ${(props) => props.theme.colors.divBackground};
  color: ${(props) => props.theme.colors.iconsSecondary};
  border: 1px solid ${(props) => props.theme.colors.cardHighlight};
  border-radius: 6px;
  width: 50%;
  padding: 10px;

  margin-top: 10px;
  list-style: none;
  overflow-y: hidden;
  padding-bottom: 80px;

  & + div {
    margin-left: 10px;
  }

  & > span {
    margin-left: 10px;
    ${(props) => props.theme.fonts.primary.H3SemiBold};
  }
`;

export const FilterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  & > div:first-child > span {
    margin-left: 10px;
    ${(props) => props.theme.fonts.primary.H2Bold};
  }

  & > div {
    display: flex;
    gap: 5px;
    height: 48px;
    align-items: center;
  }
`;

export const FilterButton = styled.button<{ active?: boolean }>`
  background-color: ${(props) => props.theme.colors.cardBackground};
  opacity: ${(props) => (props.active ? 1 : 0.8)};
  margin-right: 5px;
  border: 1px solid ${(props) => props.theme.colors.cardHighlight};
  color: ${(props) => props.theme.colors.iconsSecondary};
  padding: 5px;
  border-radius: 6px;
  height: 48px;
  width: 48px;
  display: flex;
  justify-content: center;

  align-items: center;
  svg {
    font-size: 22px;
  }

  &:hover {
    background-color: ${(props) => props.theme.colors.cardHighlight};
    
  }
  &:active {
    svg {
      font-size: 28px;
    }
  }
`;

export const FilterText = styled.input`
  box-sizing: border-box;
  width: 80%;
  max-width: 327px;
  height: 48px;
  background: #121921;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  color: ${(props) => props.theme.colors.iconsSecondary};
  padding-left: 10px;
`;

export const BoltDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SensorDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DetailsContainerHeader = styled.div`
  display: flex;
  justify-content: space-between;

  & > button {
    background-color: ${(props) => props.theme.colors.cardBackground};
    margin-right: 5px;
    border: 1px solid ${(props) => props.theme.colors.cardHighlight};
    color: ${(props) => props.theme.colors.iconsSecondary};
    padding: 5px;
    border-radius: 6px;
  }
`;

export const CompanyNameInput = styled.input`
  box-sizing: border-box;
  width: 80%;
  max-width: 327px;
  height: 48px;
  background: #121921;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  color: ${(props) => props.theme.colors.iconsSecondary};
  padding-left: 10px;
`;
export const InfoButtonContainer = styled.button`
  width: 26px;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: transparent;
  border: none;
  color: ${(props) => props.theme.colors.iconsSecondary};

  &:hover {
    background-color: ${(props) => props.theme.colors.cardHighlight};
    border-radius: 60px;
    cursor: pointer;
  }

  // Disable inside clickable elements
  & > * {
    pointer-events: none;
  }
`;

export const Triangle = styled.div<{ left?: number }>`
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-bottom: 7px solid transparent;
  left: ${(props) => props.left && props.left - -10 + "px"};
  top: 43px;
  border-left: 6px solid ${(props) => props.theme.colors.iconsSecondaryAlpha};
  position: absolute;
  transform: rotate(270deg);

  @media screen and (max-width: 767px) {
    top: 23px;
  } ;
`;

export const InfoContainer = styled.div<{ left?: number }>`
  width: 300px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  color: ${(props) => props.theme.colors.iconsSecondary};
  background-color: ${(props) => props.theme.colors.background};
  border: 1px solid ${(props) => props.theme.colors.cardBackground};
  border-radius: 6px;
  padding: 5px;
  left: ${(props) => props.left && props.left - 135 + "px"};
  top: 53px;
  flex-direction: column;
  gap: 8px;
  z-index: 2;
  text-align: justify;
  span {
    ${(props) => props.theme.fonts.primary.H5Regular};
  }

  @media screen and (max-width: 767px) {
    top: 32px;
    left: ${(props) => props.left && props.left - 270 + "px"};
  } ;
`;
