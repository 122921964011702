import { Container } from "./styles";
import { ButtonProps } from "./types";

export const Button = ({
  children,
  onClick,
  variant,
  type,
  disabled,
}: ButtonProps) => {
  return (
    <Container
      onClick={onClick}
      variant={variant}
      type={type}
      disabled={disabled}
    >
      {children}
    </Container>
  );
};
