import { Link } from "react-router-dom";
import styled from "styled-components";
import { ContainerSidebarProps } from "./types";

export const Container = styled.div<ContainerSidebarProps>`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.divBackground};
  color: ${(props) => props.theme.colors.iconsSecondary};
  border: 1px solid ${(props) => props.theme.colors.cardHighlight};
  border-radius: 5px;
  margin-right: 5px;
  width: 270px;
  min-width: 270px; // verificar em Frame.tsx qual a "width" do FrameBodyRight
  padding: 10px;
  /* height: calc(100vh - 130px); */

  @media (max-width: 767px) {
    width: ${(props) => (props.visible ? "100%" : "0px")};
    opacity: ${(props) => (props.visible ? "1" : "0")};
    transition: width 0.3s linear, opacity 0.3s linear;
    margin-left: 0px;
    margin-right: 0px;
    padding: 5px;
  }

  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Title = styled.h2`
  margin-top: 20px;
  ${(props) => props.theme.fonts.primary.H3SemiBold};
`;

export const Item = styled(Link)`
  margin-left: 10px;
  text-decoration: none;
  color: ${(props) => props.theme.colors.textPrimary};

  &:hover {
    color: ${(props) => props.theme.colors.colorTools};
  }
`;
