import styled from "styled-components";

import { ButtonContainerProps } from "./types";

export const Container = styled.button<ButtonContainerProps>`
  padding: 5px 10px;
  margin-right: 5px;

  background-color: ${(props) => props.theme.colors.cardBackground};
  /* opacity: ${(props) => (props.selected ? 1 : 0.4)}; */

  border: 1px solid ${(props) => props.theme.colors.cardHighlight};
  color: ${(props) => props.theme.colors.iconsSecondary};
  border-radius: 6px;

  background-color: ${(props) =>
    props.variant === "primary" && props.theme.colors.lightOrange};

  background-color: ${(props) => props.disabled && "gray"};
  color: ${(props) => props.theme.colors.textPrimary};

  &:disabled{
    cursor: not-allowed;
  }
`;
