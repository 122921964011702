import { RecordProps } from "../../../../types/default";
import { ListContainer } from "./styles";

export const ListGatewayDetails = ({ id, record }: RecordProps) => {
  const {
    healthCheckDate,
    operationFrequency,
    operationMode,
    version,
    lastBooted,
    numBoardSlots,
    healthCheckDetails,
  } = record;

  const percentPRAM = healthCheckDetails?.system?.details?.freePSRAM / 4188843;

  let percentColor = "inherit";
  if (percentPRAM && percentPRAM < 0.5 && percentPRAM > 0.1) {
    percentColor = "yellow";
  } else if (percentPRAM && percentPRAM < 0.1) {
    percentColor = "red";
  }

  return (
    <ListContainer>
      <div>
        <span>Frequência: </span>
        <span>{operationFrequency && `${operationFrequency}`}</span>{" "}
      </div>
      <div>
        <span>Firmware: </span>
        <span>{version}</span>
      </div>
      <div>
        <span>Modo de operação: </span>
        <span>{operationMode}</span>
      </div>
      <div>
        <span>Ultima inicialização: </span>
        <span>
          {lastBooted &&
            new Intl.DateTimeFormat("pt-BR", {
              dateStyle: "short",
              timeStyle: "medium",
            }).format(new Date(lastBooted))}
        </span>
      </div>
      <div>
        <span>Sinal de vida: </span>
        <span>
          {healthCheckDate &&
            new Intl.DateTimeFormat("pt-BR", {
              dateStyle: "short",
              timeStyle: "medium",
            }).format(new Date(healthCheckDate))}
        </span>
      </div>
      <div>
        <span>Nº sensores: </span>
        <span>{numBoardSlots}</span>
      </div>
      <div>
        <span>Alimentação: </span>
        <span>
          {!!healthCheckDetails?.electrical?.batteryVoltage
            ? `Bateria (${healthCheckDetails?.electrical?.batteryVoltage.toFixed(
                2
              )}V)`
            : "Rede eletrica"}
        </span>
      </div>
      {!!healthCheckDetails?.system?.details?.freePSRAM && (
        <div>
          <span>PRAM livre: </span>
          <span
            style={{
              color: percentColor,
            }}
          >
            {`${(percentPRAM * 100).toFixed(2)}%`}
          </span>
        </div>
      )}
    </ListContainer>
  );
};
