import { useRef } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import { ContainerDatePicker, DateInputContainer } from "./styles";
import { VscCalendar } from "react-icons/vsc";
import "react-datepicker/dist/react-datepicker.css";
import ptBR from "date-fns/locale/pt-BR";

interface IDateInput {
  name: string;
  handleChange: (date: any) => void;
  value: any;
  label?: any;
  format?: string;
  minDate?: any;
  maxDate?: any;
  placeholder?: string;
}

export const DateInput = ({
  label,
  name,
  format,
  minDate,
  maxDate,
  placeholder,
  value,
  handleChange,
}: IDateInput) => {
  registerLocale("ptBR", ptBR);
  const pickerRef = useRef<any>(null);

  return (
    <DateInputContainer>
      {label && <label>{label}</label>}
      <ContainerDatePicker>
        <DatePicker
          // onFocus={(e) => isMobile && (e.target.readOnly = true)}
          ref={pickerRef}
          name={name}
          maxDate={
            maxDate ? new Date(new Date(maxDate).getTime() + 86400000) : null
          }
          value={value}
          selected={value}
          locale={ptBR}
          className="DatePicker-container"
          dateFormat={format || "dd/MM/yyyy"}
          showPopperArrow={false}
          placeholderText={placeholder}
          onChange={handleChange}
          minDate={
            minDate ? new Date(new Date(minDate).getTime() + 86400000) : null
          }
        />
        <VscCalendar
          size={25}
          onClick={() => {
            if (!pickerRef.current.state.open) {
              pickerRef.current.setOpen(true);
              console.log(pickerRef.current.state);
            }
          }}
        />
      </ContainerDatePicker>
    </DateInputContainer>
  );
};
