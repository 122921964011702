import { RecordProps } from "../../../../types/default";
import { ListContainer } from "./styles";

export const ListGatewayConnectivity = ({ id, record }: RecordProps) => {
  const {
    gatewayId,
    schedulePosition,
    synchronized,
    healthCheckDate,
    gateway,
    createdAt,
    operationFrequency,
    operationMode,
    version,
    lastBooted,
    numBoardSlots,
    healthCheckDetails,
  } = record;

  const isGSM = version?.includes("GSM");
  const isWifi = version?.includes("WIFI");

  const rssi = isWifi
    ? healthCheckDetails?.wiFiNetwork?.rssi
    : healthCheckDetails?.mobileNetwork?.rssi;

  return (
    <ListContainer>
      <div>
        <span>Tipo: </span>
        <span>{version && ((isGSM && "GSM") || (isWifi && "Wifi"))}</span>
      </div>
      <div>
        <span>Mac Address: </span>
        <span>{healthCheckDetails?.wiFiNetwork?.macAddress}</span>
      </div>
      <div>
        <span>RSSI: </span>
        <span>{rssi}</span>
      </div>
      {isWifi && (
        <div>
          <span>Nome da rede: </span>
          <span>{healthCheckDetails?.wiFiNetwork?.ssid}</span>
        </div>
      )}
      {isGSM && (
        <>
          <div>
            <span>Nome da rede: </span>
            <span>{healthCheckDetails?.mobileNetwork?.operator}</span>
          </div>
          <div>
            <span>IMEI: </span>
            <span>{healthCheckDetails?.mobileNetwork?.imei}</span>
          </div>
          <div>
            <span>simCCID: </span>
            <span>{healthCheckDetails?.mobileNetwork?.simCCID}</span>
          </div>
        </>
      )}
    </ListContainer>
  );
};
