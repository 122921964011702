import styled from "styled-components";

export const DateInputContainer = styled.div`
  display: flex !important;
  flex-direction: column !important;
  gap: 5px !important;
  width: 100%;
  label {
    color: ${({ theme }) => theme.colors.textPrimary};
    ${(props) => props.theme.fonts.primary.H4Regular};
    text-transform: uppercase;
  }
`;

export const ContainerDatePicker = styled.div`
  border: 1px solid ${(props) => props.theme.colors.cardHighlight};
  background: ${(props) => props.theme.colors.white};
  height: 38px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 10px;
  ${(props) => props.theme.fonts.primary.H4Regular};
  min-height: 34px;
  color: ${(props) => props.theme.colors.iconsPrimary};

  border-radius: 4px;


  &:hover {
    border: 0.5px solid ${(props) => props.theme.colors.IconsTools};
  }

  &:focus-visible {
    outline: none;
    border: 1px solid ${(props) => props.theme.colors.IconsTools};
  }

  & .react-datepicker-wrapper {
    height: 100%;
    padding: 0;
    margin: 0;
  }

  &.react-datepicker__input-container {
    padding: 0;
    margin: 0;
  }

  & .DatePicker-container {
    flex-direction: row !important;
    background-color: ${(props) => props.theme.colors.white};
    width: 100%;
    color: ${(props) => props.theme.colors.iconsPrimary};
    border-radius: 6px;
    border: none;
    margin: 0;
    padding: 8px;
    ${(props) => props.theme.fonts.primary.H4Regular};
    outline: none !important;
    height: 100%;
  }

  & .react-datepicker {
    -webkit-flex-direction: row !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
    width: 100%;
  }

  & .react-datepicker__month-container {
    flex-direction: row !important;
    width: 100% !important;
  }

  & .react-datepicker__header {
    width: 100%;
    background-color: ${(props) => props.theme.colors.cardHighlight};
    border-color: ${(props) => props.theme.colors.cardHighlight};
    & div {
      color: ${(props) => props.theme.colors.iconsSecondary} !important;
    }
  }

  & .DatePicker-container-calendar {
    z-index: 1000000000;
    width: 100%;
    min-width: 200px;
    background-color: ${(props) => props.theme.colors.cardBackground};
    border-color: ${(props) => props.theme.colors.cardHighlight};

    .react-datepicker__current-month,
    .react-datepicker-time__header,
    .react-datepicker-year-header {
      width: 100%;
    }

    .react-datepicker__month-container {
      width: 100%;
    }

    .react-datepicker__day {
      color: ${(props) => props.theme.colors.iconsSecondary};
      border-radius: 100%;
      &:hover {
        background-color: ${(props) => props.theme.colors.cardHighlight};
      }
      &--selected {
        background-color: ${(props) => props.theme.colors.ibbxOrange};
      }
    }
  }

  svg {
    font-size: 20px;
    margin-right: 8px;
  }

  // Seletores de data e período dos gráficos globais
  @media screen and (max-width: 767px) {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    top: 54px;

    div:first-child {
      margin-right: 0%;
    }
  }
`;
