import { Amplify, API } from "aws-amplify";

import { awsConfig } from "../store/awsConfig";
import { error } from "../components/Toast";

Amplify.configure(awsConfig);
const apiRetinaMonitorator = awsConfig.API.endpoints[0].name;

type RequestDeleteProps = { path: string; queryStringParameters?: any };

export async function del({ path, queryStringParameters }: RequestDeleteProps) {
  try {
    const response = await API.del(apiRetinaMonitorator, path, {
      queryStringParameters,
    });
    return response;
  } catch (err: any) {
    const { data }: any = err?.response || {};
    error(data?.message || "Failed to request");
    throw err;
  }
}

type ResquestPutProps = { path: string; body?: any };

export async function put({ path, body }: ResquestPutProps) {
  try {
    const response = await API.put(apiRetinaMonitorator, path, {
      body,
    });
    return response;
  } catch (err: any) {
    const { data }: any = err?.response || {};
    error(data?.message || "Failed to request");
    throw err;
  }
}
