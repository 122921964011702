import { useSearchParams } from "react-router-dom";
import { Button } from "../../../components";
import { ListBoolean } from "../../../components/List/ListBoolean";
import { RecordProps } from "../../../types/default";

const defaultDateOptions = {
  dateStyle: "short",
  timeStyle: "short",
};
function dateFormat(date: any, options?: any) {
  if (!isNaN(date)) {
    date = +date;
  }

  if (date) {
    try {
      return new Intl.DateTimeFormat(
        "pt-BR",
        options || defaultDateOptions
      ).format(new Date(date));
    } catch (err) {
      return date;
    }
  }

  return "";
}

export const Endpoints = {
  title: "Endpoints",
  urlPath: "/summary/endpoints",
  apiPath: "/summary/endpoints",
  tabelao: "ENDPOINTS",
  actions: {
    new: { isVisible: false },
    show: { isVisible: false },
    edit: { isVisible: false },
    delete: { isVisible: false },
    csv: { isVisible: true, limit: 100 },
    viewHistory: {
      name: "history",
      type: "record",
      label: "Histórico",
      component: (record: any) => <></>,
    },
    currentDate: {
      name: "current",
      label: "Atual",
      type: "resource",
      Component: () => {
        const [searchParams, setQueryString] = useSearchParams();

        const minDate = new Date().getTime() - 1000 * 60 * 60 * 2;

        const hasParam = !!searchParams.get("date");

        const variant = hasParam ? "primary" : "";

        return (
          <Button
            variant={variant}
            onClick={() => {
              if (hasParam) {
                setQueryString();
              } else {
                setQueryString({
                  date: `GT${minDate}`,
                } as any);
              }
            }}
          >
            Atual
          </Button>
        );
      },
    },
  },
  properties: {
    boardId: {
      id: "boardId",
      type: "TEXT",
      label: "UUID",
      isPrimaryKey: true,
      isVisible: {
        filter: true,
      },
    },
    lastUpdate: {
      id: "date",
      type: "DATE_PERIOD",
      label: "Ulitma Atualização",
      formatedValue: ({ record, id }: RecordProps) => dateFormat(record[id]),
    },
    isConnected: {
      id: "isConnected",
      type: "SELECT",
      label: "Staus Conexão",
      component: {
        list: ({ record, id }: any) => (
          <ListBoolean
            record={record}
            id={id}
            true="Conectado"
            false="Desconectado"
          />
        ),
      },
      isVisible: {
        list: true,
        filter: true,
      },
      options: [
        { value: true, label: "Conectado" },
        { value: false, label: "Desconectado" },
      ],
    },
    performance: {
      id: "performance",
      type: "RANGE",
      label: "Performance",
      isVisible: {
        list: true,
        filter: true,
      },
      formatedValue: ({ record, id }: RecordProps) =>
        record[id] && `${record[id]}%`,
    },
    connectivityPercent: {
      id: "connectivityPercent",
      type: "RANGE",
      label: "Conexão Gateway",
      isVisible: {
        list: true,
        filter: true,
      },
      formatedValue: ({ record, id }: RecordProps) =>
        record[id] && `${record[id]}%`,
    },
    lastAcquisitionDate: {
      id: "lastAcquisitionDate",
      type: "DATE_PERIOD",
      label: "Ultima Conexão",
      isVisible: {
        list: true,
        filter: true,
      },
      formatedValue: ({ record, id }: RecordProps) => dateFormat(record[id]),
    },
    id: {
      id: "boardId",
      type: "TEXT",
      label: "ID",
      isVisible: {
        list: true,
      },
      openLink: ({ record, id }: any) =>
        record["boardId"]
          ? `${process.env.REACT_APP_RETINA_INFRA_URL}/monitorator/collects?activatorId=${record["boardId"]}`
          : null,
    },
    partnumber: {
      id: "partnumber",
      type: "SELECT",
      label: "Partnumber",
      isVisible: {
        list: true,
        filter: true,
      },
      options: [
        { value: "spectrapic24915v1", label: "spectrapic24915v1" },
        { value: "connectpic24915v1 ", label: "connectpic24915v1" },
        { value: "connectcube915v1", label: "connectcube915v1" },
        { value: "spectramensor", label: "spectramensor" },
      ],
    },
    firmware: {
      id: "firmware",
      type: "TEXT",
      label: "Firmware",
      isVisible: {
        list: true,
        filter: true,
      },
    },
    manufacturer: {
      id: "manufacturer",
      type: "SELECT",
      label: "Fabricante",
      isVisible: {
        list: true,
        filter: true,
      },
      options: [{ value: "Etronic", label: "Etronic" }],
    },
    batch: {
      id: "batch",
      type: "TEXT",
      label: "Lote",
      isVisible: {
        list: false,
        filter: false,
      },
    },
    companyId: {
      id: "companyId",
      type: "SELECT_HIEARCHY",
      label: "Empresa",
      endpoint: "/companies-options",
      resetOnChange: ["facilityId", "assetId", "positionId"],
      isVisible: {
        list: false,
        filter: true,
      },
    },
    companyName: {
      id: "companyName",
      label: "Empresa",
      width: "200px",
      isVisible: {
        list: true,
        filter: false,
      },
      openLink: ({ record, id }: any) =>
        record["companyId"]
          ? `${process.env.REACT_APP_RETINA_WEB_URL}/companies/${record["companyId"]}/facilities`
          : null,
    },
    facilityId: {
      id: "facilityId",
      type: "SELECT_HIEARCHY",
      label: "Unidade",
      endpoint: "/facilities-options",
      parent: "companyId",
      resetOnChange: ["assetId", "positionId"],
      isVisible: {
        list: false,
        filter: true,
      },
    },
    facilityName: {
      id: "facilityName",
      label: "Unidade",
      width: "200px",
      isVisible: {
        list: true,
        filter: false,
      },
      openLink: ({ record, id }: any) =>
        record["companyId"] && record["facilityId"]
          ? `${process.env.REACT_APP_RETINA_WEB_URL}/companies/${record["companyId"]}/facilities/${record["facilityId"]}/assets`
          : null,
    },
    sector: {
      id: "sector",
      label: "Setor",
      widtn: "200px",
      isVisible: {
        list: true,
        filter: false,
      },
    },
    assetId: {
      id: "assetId",
      type: "SELECT_HIEARCHY",
      label: "Ativo",
      endpoint: "/assets-options",
      parent: "facilityId",
      resetOnChange: ["positionId"],
      isVisible: {
        list: false,
        filter: true,
      },
    },
    assetName: {
      id: "assetName",
      label: "Ativo",
      widtn: "200px",
      isVisible: {
        list: true,
        filter: false,
      },
      openLink: ({ record, id }: any) =>
        record["companyId"] && record["facilityId"] && record["assetId"]
          ? `${process.env.REACT_APP_RETINA_WEB_URL}/companies/${record["companyId"]}/facilities/${record["facilityId"]}/assets/${record["assetId"]}`
          : null,
    },
    positionName: {
      id: "positionName",
      label: "Ponto",
      widtn: "200px",
      isVisible: {
        list: true,
        filter: false,
      },
      openLink: ({ record, id }: any) =>
        record[id]
          ? `${process.env.REACT_APP_RETINA_WEB_URL}/positions/${record["positionId"]}`
          : null,
    },
    positionId: {
      id: "positionId",
      type: "SELECT_HIEARCHY",
      label: "ID do Ponto",
      endpoint: "/positions-options",
      parent: "assetId",
      resetOnChange: [],
      isVisible: {
        list: false,
        filter: true,
      },
    },
    positionIdText: {
      id: "positionIdText",
      type: "TEXT",
      label: "ID do Ponto",
      isVisible: {
        list: true,
        filter: true,
      },
    },
    sysSensorTypeName: {
      id: "sysSensorTypeName",
      type: "SELECT",
      label: "Monitoramento",
      isVisible: {
        list: true,
        filter: true,
      },
      options: [
        { value: "Vibração e Temperatura", label: "Vibração e Temperatura" },
        { value: "Energia Elétrica", label: "Energia Elétrica" },
        { value: "Pressão", label: "Pressão" },
        { value: "Modbus", label: "Modbus" },
        { value: "Analógico Modular", label: "Analógico Modular" },
      ],
    },
    spectralWindow: {
      id: "spectralWindow",
      label: "Janela(Hz)",
      isVisible: {
        list: true,
        filter: false,
      },
      component: {
        list: ({ record, id }: any) => (
          <span style={{ color: "white" }}>
            {record?.sysSensorTypeId === 1
              ? record.spectralWindow || "6400"
              : "-"}
          </span>
        ),
      },
    },
    scheduleSynchronized: {
      id: "scheduleSynchronized",
      type: "SELECT",
      label: "Status Sincronização",
      component: {
        list: ({ record, id }: any) => (
          <ListBoolean
            record={record}
            id={id}
            true="Sincronizado"
            false="Desincronizado"
          />
        ),
      },
      isVisible: {
        list: true,
        filter: true,
      },
      options: [
        { value: true, label: "Sincronizado" },
        { value: false, label: "Desincronizado" },
      ],
    },
    isActivated: {
      id: "isActivated",
      type: "SELECT",
      label: "Status Ativação",
      component: {
        list: ({ record, id }: any) => (
          <ListBoolean
            record={record}
            id={id}
            true="Ativado"
            false="Desativado"
          />
        ),
      },
      isVisible: {
        list: true,
        filter: true,
      },
      options: [
        { value: true, label: "Ativado" },
        { value: false, label: "Desativado" },
      ],
    },
    activatedAt: {
      id: "activatedAt",
      type: "DATE_PERIOD",
      label: "Ultima Ativação",
      isVisible: {
        list: true,
        filter: true,
      },
      formatedValue: ({ record, id }: RecordProps) => dateFormat(record[id]),
    },
    daysActivated: {
      id: "daysActivated",
      type: "RANGE",
      label: "Dias Ativado",
      isVisible: {
        list: true,
        filter: false,
      },
      formatedValue: ({ record, id }: RecordProps) => {
        if (!record["isActivated"] || !record["activatedAt"]) {
          return "";
        }
        const timeActivated = new Date().getTime() - record["activatedAt"];
        return Math.ceil(timeActivated / 86400000);
      },
    },
    daysConnected: {
      id: "daysConnected",
      type: "RANGE",
      label: "Dias Conectado",
      isVisible: {
        list: true,
        filter: true,
      },
    },
    gatewayId: {
      id: "gatewayId",
      type: "TEXT",
      label: "Gateway ID",
      isVisible: {
        list: true,
        filter: true,
      },
      openLink: ({ record, id }: any) =>
        record[id]
          ? `${process.env.REACT_APP_RETINA_INFRA_URL}/summary/gateways/${record[id]}/history`
          : null,
    },
    gatewayName: {
      id: "gatewayName",
      type: "TEXT",
      label: "Gateway Name",
      isVisible: {
        list: true,
        filter: true,
      },
    },
    schedulePosition: {
      id: "schedulePosition",
      type: "RANGE",
      label: "Slot do Agendamento",
      isVisible: {
        list: true,
        filter: true,
      },
    },
    acquisitionInterval: {
      id: "acquisitionInterval",
      type: "RANGE",
      label: "Intervalo Coletas(min)",
      isVisible: {
        list: true,
        filter: false,
      },
    },
    globalTransmissionInterval: {
      id: "globalTransmissionInterval",
      type: "RANGE",
      label: "Intervalo Globais",
      isVisible: {
        list: true,
        filter: false,
      },
      formatedValue: ({ record, id }: RecordProps) =>
        record[id] && `${record[id].toFixed(2)} min`,
    },
    dynamicTransmissionInterval: {
      id: "dynamicTransmissionInterval",
      type: "RANGE",
      label: "Intervalo Dinâmicas",
      isVisible: {
        list: true,
        filter: false,
      },
      formatedValue: ({ record, id }: RecordProps) =>
        record[id] && `${record[id].toFixed(2)} min`,
    },
    scheduleRssi: {
      id: "scheduleRssi",
      type: "RANGE",
      label: "Potência sinal Sincronizaçao",
      isVisible: {
        list: true,
        filter: true,
      },
    },
    lastCollectRSSI: {
      id: "lastCollectRSSI",
      type: "RANGE",
      label: "Potência sinal Transmissão",
      isVisible: {
        list: true,
        filter: true,
      },
    },
    signalQuality: {
      id: "signalQuality",
      type: "RANGE",
      label: "Qualidade do Sinal",
      isVisible: {
        list: true,
        filter: true,
      },
    },
    nextGlobalAt: {
      id: "nextGlobalAt",
      type: "TEXT",
      label: "Próxima Global",
      isVisible: {
        list: true,
        filter: false,
      },
      formatedValue: ({ record, id }: RecordProps) =>
        dateFormat(record[id], { timeStyle: "medium" }),
    },
    nextDynamicAt: {
      id: "nextDynamicAt",
      type: "TEXT",
      label: "Próxima Dinâmica",
      isVisible: {
        list: true,
        filter: false,
      },
      formatedValue: ({ record, id }: RecordProps) =>
        dateFormat(record[id], { timeStyle: "short" }),
    },
    nextConfigAt: {
      id: "nextConfigAt",
      type: "TEXT",
      label: "Próxima Configuração",
      isVisible: {
        list: true,
        filter: false,
      },
      formatedValue: ({ record, id }: RecordProps) =>
        dateFormat(record[id], { timeStyle: "short" }),
    },
    initialBatteryVoltage: {
      id: "initialBatteryVoltage",
      type: "RANGE",
      label: "Tensão Inicial bateria(V)",
      isVisible: {
        list: true,
        filter: true,
      },
      formatedValue: ({ record, id }: RecordProps) =>
        record[id] && record[id].toFixed(2),
    },
    batteryVoltage: {
      id: "batteryVoltage",
      type: "RANGE",
      label: "Tensão Atual da bateria(V)",
      isVisible: {
        list: true,
        filter: true,
      },
      formatedValue: ({ record, id }: RecordProps) =>
        record[id] && record[id].toFixed(2),
    },
    batteryPercent: {
      id: "batteryPercent",
      type: "RANGE",
      label: "Carga Atual Bateria(%)",
      isVisible: {
        list: true,
        filter: true,
      },
      formatedValue: ({ record, id }: RecordProps) =>
        record[id] && record[id].toFixed(2),
    },
    batteryConsumption: {
      id: "batteryConsumption",
      type: "RANGE",
      label: "Consumo médio (mAh)",
      isVisible: {
        list: true,
        filter: true,
      },
      formatedValue: ({ record, id }: RecordProps) =>
        (record[id] || "") && record[id].toFixed(2),
    },
    temperature: {
      id: "temperature",
      type: "RANGE",
      label: "Temperatura (C)",
      isVisible: {
        list: true,
        filter: true,
      },
    },
    operationFrequency: {
      id: "operationFrequency",
      type: "TEXT",
      label: "Frequência de Tx(MHz)",
      isVisible: {
        list: true,
        filter: true,
      },
    },
    batteryLifeForecast: {
      id: "batteryLifeForecast",
      type: "DATE_PERIOD",
      label: "Previsão de vida da bateria",
      isVisible: {
        list: true,
        filter: true,
      },
      formatedValue: ({ record, id }: RecordProps) => dateFormat(record[id]),
    },
    recallForecast: {
      id: "recallForecast",
      type: "DATE_PERIOD",
      label: "Recall programado",
      isVisible: {
        list: true,
        filter: true,
      },
      formatedValue: ({ record, id }: RecordProps) => dateFormat(record[id]),
    },
    lastRecallAt: {
      id: "lastRecallAt",
      type: "DATE_PERIOD",
      label: "Ultimo Recall realizado",
      isVisible: {
        list: true,
        filter: true,
      },
      formatedValue: ({ record, id }: RecordProps) => dateFormat(record[id]),
    },
    isRecall: {
      id: "isRecall",
      type: "SELECT",
      label: "Recall realizado",
      formatedValue: ({ record, id }: RecordProps) => (record[id] ? "Sim" : ""),
      isVisible: {
        list: false,
        filter: false,
      },
      options: [
        { value: false, label: "Sim" },
        { value: false, label: "Não" },
      ],
    },
    recallReason: {
      id: "recallReason",
      type: "TEXT",
      label: "Motivo Recall",
      isVisible: {
        list: true,
        filter: true,
      },
    },
    totalGatewaysListen: {
      id: "totalGatewaysListen",
      type: "TEXT",
      label: "Total gateways escutando",
      isVisible: {
        list: false,
      },
    },
    availableGatewaysListen: {
      id: "availableGatewaysListen",
      type: "TEXT",
      label: "Gateways sinal bom",
      isVisible: {
        list: false,
      },
    },
    unavailableGatewaysListen: {
      id: "unavailableGatewaysListen",
      type: "TEXT",
      label: "Gateways sinal ruim",
      isVisible: {
        list: false,
      },
    },
  },
};
