import React, { useState } from "react";
import { Modal } from "../Modal";

import { Container, Button } from "./styles";

import { ModalConfirmProps } from "./types";

export const ModalConfirm = ({
  message,
  handleConfirm,
  children,
}: ModalConfirmProps) => {
  const [show, setShow] = useState(false);

  return (
    <>
      <Button onClick={() => setShow(true)}>{children}</Button>
      <Modal
        handleClose={() => setShow(false)}
        show={show}
        handleConfirm={handleConfirm}
        confirmLabel="Sim"
      >
        <Container>
          <span>{message}</span>
        </Container>
      </Modal>
    </>
  );
};
