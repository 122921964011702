import { useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import queryString from "query-string";

import { ButtonCancel, Container, Footer, Title } from "./styles";

import { useLocation } from "react-router-dom";
import { Modal } from "../Modal";
import { Button } from "../Button";
import useApi from "../../hooks/useApi";
import { dateFormat } from "../../modules/summary/resources/Gateways";
import { formatValueToCsv } from "../../utils/csvUtils";

type ModalExportCSVProps = {
  resource: any;
};

export const ModalExportCSV = ({ resource }: ModalExportCSVProps) => {
  const location = useLocation();
  const csvRef = useRef<any>(null);

  const { request } = useApi({
    path: resource.apiPath,
  });

  const [show, setShow] = useState(false);
  const [message, setMessage] = useState<String>("");
  const [data, setData] = useState<any>([]);
  const [completed, setCompleted] = useState(false);

  async function fetchPages() {
    setMessage("Buscando dados 0%...");
    setCompleted(false);

    const params = queryString.parse(location.search);
    delete params.page;

    let response, pagination;
    let nextLastKeyEvaluated: any = null;
    let page = 1;
    let accData: any = [];

    do {
      response = await request({
        method: "get",
        queryStringParameters: {
          page,
          lastKeyEvaluatedPk: nextLastKeyEvaluated?.pk || "",
          lastKeyEvaluatedSk: nextLastKeyEvaluated?.sk || "",
          limit: resource.actions.csv?.limit || 10,
          ...(resource?.queryParams || {}),
          ...params,
        },
      });

      pagination = response.pagination;
      page++;
      accData = [...accData, ...response.data];
      nextLastKeyEvaluated = null;
      if (response.lastKeyEvaluated) {
        nextLastKeyEvaluated = response.lastKeyEvaluated;
        setMessage(
          `Buscando dados... \n Encontrados ${accData.length} Registros`
        );
      }

      if (pagination?.page) {
        setMessage(
          `Buscando dados ${Math.trunc(
            (pagination?.page / pagination?.totalPages) * 100
          )}% ...`
        );
      }
    } while (
      pagination?.page < pagination?.totalPages ||
      !!nextLastKeyEvaluated
    );

    let formattedData = [];
    if (resource.customFormatter) {
      formattedData = resource.customFormatter(accData);
    } else {
      formattedData = accData.map((row: any) => {
        return Object.values(resource.properties).reduce(
          (acc: any, item: any) => {
            const { label, id, formatedValue, type } = item;
            let value = row?.[id];

            if (formatedValue && type !== "RANGE") {
              value = formatedValue({ id, record: row });
            }
            if (type === "RANGE" || id === "operationFrequency") {
              value = value?.toString() || "";
              const [int, dec] = value.split(".");
              value = (int || "") + (dec ? `,${dec}` : "");
            }
            if (type === "DATE_PERIOD") {
              value = value?.replace(",", "");
            }

            if (label === "DATA") {
              value = dateFormat(value);
            }

            acc[label] = formatValueToCsv(value);
            return acc;
          },
          {}
        );
      });
    }

    setMessage("Relatório pronto!");
    setCompleted(true);
    setData(formattedData);
  }

  useEffect(() => {
    if (show) {
      fetchPages();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  const date = new Date();

  return (
    <>
      <Button onClick={() => setShow(true)}>Exportar CSV</Button>
      <Modal
        handleClose={() => setShow(false)}
        show={show}
        confirmLabel="Baixar"
        size="lg"
        disabledConfirm={false}
        changeFotter
      >
        <Container>
          <Title>
            <strong>{`Exportando dados`}</strong>
          </Title>
          <p>{message}</p>
          {completed && (
            <CSVLink
              separator=";"
              style={{ display: "none" }}
              ref={csvRef}
              data={data}
              filename={`${
                resource.actions.csv?.filename || "csv-export"
              }-${date.getDay()}/${date.getMonth() + 1}.csv`}
              className=""
            ></CSVLink>
          )}
          <Footer>
            <div>
              <ButtonCancel onClick={() => setShow(false)}>
                Cancelar
              </ButtonCancel>
              <Button
                disabled={!completed}
                variant="primary"
                onClick={() => {
                  csvRef.current.link.click();
                }}
              >
                Baixar
              </Button>
            </div>
          </Footer>
        </Container>
      </Modal>
    </>
  );
};
